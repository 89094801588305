@font-face {
    font-family: "Gilroy-Medium";
    src:
        local("Gilroy-Medium"),
        url(./fonts/gilroy/Gilroy-Medium.ttf) format("truetype");
}
@font-face {
    font-family: "Gilroy-SemiBold";
    src:
        local("Gilroy-SemiBold"),
        url(./fonts/gilroy/Gilroy-SemiBold.ttf) format("truetype");
}
@font-face {
    font-family: "Gilroy-MediumItalic";
    src:
        local("Gilroy-MediumItalic"),
        url(./fonts/gilroy/Gilroy-MediumItalic.ttf) format("truetype");
}
@font-face {
    font-family: "Gilroy-Regular";
    src:
        local("Gilroy-Regular"),
        url(./fonts/gilroy/Gilroy-Regular.ttf) format("truetype");
}

body {
    margin: 0;
    font-family: "Gilroy-Medium", "Gilroy-SemiBold", "Gilroy-Regular", "Gilroy-MediumItalic", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#file-name {
    display: none !important;
}

.markdown {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 20px;
}
