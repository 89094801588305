* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

.MuiButton-root {
    text-transform: none !important;
}

@keyframes blink {
    50% {
        fill: transparent;
    }
}
.dot {
    animation: 1s blink infinite;
    fill: grey;
}
.dot:nth-child(2) {
    animation-delay: 250ms;
}
.dot:nth-child(3) {
    animation-delay: 500ms;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
::-webkit-scrollbar-thumb {
    background: #84ceff;
    border-radius: 13px;
    width: 4px;
    height: 4px;
}
::-webkit-scrollbar-track {
    background: #f6f6f6;
    width: 4px;
    height: 4px;
}

.runnigText {
    width: 0px;
    overflow: hidden;
    text-wrap: nowrap;
    animation: runnigText 1s ease-out 0s 1 forwards;
}

@keyframes runnigText {
    25% {
        width: 25%;
    }
    50% {
        width: 50%;
    }
    100% {
        width: 100%;
    }
}

#loadPDF::before {
    width: 0%;
    animation: loadRunner 1.8s linear 0s 1 forwards;
}

@keyframes loadRunner {
    25% {
        width: 10%;
    }
    30% {
        width: 30%;
    }
    60% {
        width: 35%;
    }
    75% {
        width: 65%;
    }
    100% {
        width: 100%;
    }
}

.react-flow__attribution {
    display: none;
}

.travelFlash::before {
    content: '""';
    height: 4px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    transform-origin: "left";
    z-index: 100;
    background: #37f4ff;
    border-radius: 13px;
    animation: travelFlashSpeed 2.8s linear infinite;
}

@keyframes travelFlashSpeed {
    0% {
        opacity: 1;
    }
    25%,
    100% {
        opacity: 0.5;
    }
    75% {
        opacity: 0;
    }
}

.loop {
    width: 5px;
    height: 5px;
    background: #35a6e8;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 3px 1px #7dbfe6;
    animation: loopspin 1.2s cubic-bezier(0, 0, 0.16, 0.54) infinite;
}

.loop2 {
    animation-delay: 0.4s;
    background: #169be7;
}
.loop3 {
    animation-delay: 0.8s;
    background: #0594e8;
}

@keyframes loopspin {
    0% {
        transform: translate(2px);
        opacity: 0.2;
    }

    25% {
        transform: translate(15px, 7.5px);
        opacity: 0.7;
    }
    50% {
        transform: translate(30px, 0px);
        opacity: 0.4;
    }
    75% {
        transform: translate(15px, -7.5px);
        opacity: 0.3;
    }
    100% {
        transform: translate(2px);
        opacity: 0;
    }
}

.hotBlick {
    animation: blink 1.5s linear infinite;
}
@keyframes blink {
    0%,
    100% {
        border-color: #55555510;
    }
    50% {
        border-color: #555555ff;
        transform: scale(1.1);
    }
}

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
    display: none;
}

.dot-spin {
    color: #007bff;
    font-size: 7px;
    margin: 20px auto 40px auto;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: load4 1.4s infinite linear;
    transform: translateZ(0);
}
@keyframes load4 {
    0%,
    100% {
        box-shadow:
            0 -3em 0 0.2em,
            2em -2em 0 0em,
            3em 0 0 -1em,
            2em 2em 0 -1em,
            0 3em 0 -1em,
            -2em 2em 0 -1em,
            -3em 0 0 -1em,
            -2em -2em 0 0;
    }
    12.5% {
        box-shadow:
            0 -3em 0 0,
            2em -2em 0 0.2em,
            3em 0 0 0,
            2em 2em 0 -1em,
            0 3em 0 -1em,
            -2em 2em 0 -1em,
            -3em 0 0 -1em,
            -2em -2em 0 -1em;
    }
    25% {
        box-shadow:
            0 -3em 0 -0.5em,
            2em -2em 0 0,
            3em 0 0 0.2em,
            2em 2em 0 0,
            0 3em 0 -1em,
            -2em 2em 0 -1em,
            -3em 0 0 -1em,
            -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow:
            0 -3em 0 -1em,
            2em -2em 0 -1em,
            3em 0em 0 0,
            2em 2em 0 0.2em,
            0 3em 0 0em,
            -2em 2em 0 -1em,
            -3em 0em 0 -1em,
            -2em -2em 0 -1em;
    }
    50% {
        box-shadow:
            0 -3em 0 -1em,
            2em -2em 0 -1em,
            3em 0 0 -1em,
            2em 2em 0 0em,
            0 3em 0 0.2em,
            -2em 2em 0 0,
            -3em 0em 0 -1em,
            -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow:
            0 -3em 0 -1em,
            2em -2em 0 -1em,
            3em 0 0 -1em,
            2em 2em 0 -1em,
            0 3em 0 0,
            -2em 2em 0 0.2em,
            -3em 0 0 0,
            -2em -2em 0 -1em;
    }
    75% {
        box-shadow:
            0em -3em 0 -1em,
            2em -2em 0 -1em,
            3em 0em 0 -1em,
            2em 2em 0 -1em,
            0 3em 0 -1em,
            -2em 2em 0 0,
            -3em 0em 0 0.2em,
            -2em -2em 0 0;
    }
    87.5% {
        box-shadow:
            0em -3em 0 0,
            2em -2em 0 -1em,
            3em 0 0 -1em,
            2em 2em 0 -1em,
            0 3em 0 -1em,
            -2em 2em 0 0,
            -3em 0em 0 0,
            -2em -2em 0 0.2em;
    }
}

.opacity_blink {
    animation: blinkLoad 1.2s infinite linear;
}

@keyframes blinkLoad {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

.bounce1 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    animation: bounce 1s infinite;
    background: #000;
}

.bounce1:nth-child(2) {
    animation-delay: 150ms;
}
.bounce1:nth-child(3) {
    animation-delay: 300ms;
}

@keyframes bounce {
    0%,
    100% {
        transform: translate(0px, 0rem) scale(1.25);
    }
    20% {
        transform: translate(0px, -1.2rem) scale(0.9);
    }
}
.audio-recorder {
    background-color: inherit !important;
    box-shadow: none !important;
}

.recording {
    background-color: #ebebeb !important;
}

.dot_blick {
    animation: dotBlink 1.5s infinite linear;
}
.dot_blick:nth-child(2) {
    animation-delay: 330ms;
}
.dot_blick:nth-last-child() {
    animation-delay: 150ms;
}

@keyframes dotBlink {
    0%,
    100% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
}

.react-pdf__Page,
.react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap: 8px;
}
.react-pdf__Page__canvas {
    width: 80% !important;
    height: 50% !important;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.loading svg {
    animation: rotate 3s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

textarea:focus-within {
    outline-color: #3399ff;
}

.callbusy {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #ff4e4e;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.callbusy::before {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    opacity: 1;
    border-radius: 50%;
    border: 1px solid #ff4e4e;
    animation: process 4s infinite linear;
}

@keyframes process {
    2.5%,
    7.5%,
    12.5%,
    17.5%,
    22.5%,
    27.5% {
        width: 0px;
        height: 0px;
    }
    0%,
    5%,
    10%,
    15%,
    20%,
    15%,
    20%,
    25%,
    30% {
        width: 10px;
        height: 10px;
        opacity: 0.8;
    }
    31% {
        width: 0px;
        height: 0px;
        opacity: 0;
    }
}

.shake {
    animation: shaky 4s infinite linear;
}

@keyframes shaky {
    5%,
    15%,
    25%,
    35%,
    45% {
        transform: rotate(-10deg);
    }
    0%,
    10%,
    20%,
    30%,
    40%,
    50% {
        transform: rotate(10deg);
    }
    51%,
    100% {
        transform: rotate(0deg);
    }
}
